import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


const Counter = (props) =>
{
    const [viewPortEntered, setViewPortEntered] = useState(false);
    const counters = props.counterValue
    return (
        <div className="w-full flex flex-wrap justify-between">
            {counters.map((counter, index) =>
                <div key={index} className="mb-3-3 pr-3 mms:mb-6-6 sm:mb-0  w-full mms:w-1/3  sm:w-auto flex flex-wrap">
                        <div className="flex mms:justify-center md:justify-start">
                            <div className="mr-2-2 lg:mr-1-2 w-50 h-50 lg:w-55 lg:h-55">
                                <img src={counter.svg.publicURL} className="" alt="decodeup-projects-icon">
                                </img>
                            </div>
                            <div>
                                <h2 className="lg:mb-0-2 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-cyan">
                                    <CountUp end={counter.count} start={viewPortEntered ? null : 0} suffix="+">
                                        {({ countUpRef }) =>
                                        {
                                            return (
                                                <VisibilitySensor
                                                    active={!viewPortEntered}
                                                    onChange={isVisible =>
                                                    {
                                                        if (isVisible)
                                                        {
                                                            setViewPortEntered(true);
                                                        }
                                                    }}
                                                    delayedCall
                                                >
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            );
                                        }}
                                    </CountUp>

                                </h2>
                                <p className="text-term-primary font-poppings-bold text-p4 lg:text-p2">
                                    {counter.title}
                                </p>
                            </div>
                        </div>
                </div>
            )}
        </div>
    );
}

export default Counter;